@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value);
}

.bg-gradient-primary{
    @include linear-gradient(to right, #2f76f0 0%, #4866f2 100%);
}

.bg-gradient-secondary{
  @include linear-gradient(to right, #5030fb 0%, #5d46d9 100%);
}

.bg-gradient-success{
  @include linear-gradient(to left, #00dab6 0%, #00c2da 100%);
}

.bg-gradient-danger {
  @include linear-gradient(to left, #ff8b45 0%, #ff7d45 100%);
}

.bg-gradient-info{
  @include linear-gradient(to left, #575b86 0%, #2c2e4e 100%);
}

