
// Career Page

/*--Features--*/
.trz-our-features{}

/* entire container, keeps perspective */
.flip-card {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    -o-perspective: 1000;
    perspective: 1000;
    background: transparent;

    // -webkit-transform-style: preserve-3d;
    // -moz-transform-style: preserve-3d;
    // -ms-transform-style: preserve-3d;
    // -o-transform-style: preserve-3d;
    // transform-style: preserve-3d;
}

/*  UPDATED! flip the pane when hovered */
.flip-card:hover .back, .flip-card.hover .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
}
.flip-card:hover .front, .flip-card.hover .front{
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.flip-card, .front, .back {
    @include size(100%, calculateRem(250));
    
    @include media-breakpoint-only(md) {
        height:calculateRem(300);
    }       
}

/* flip speed goes here */
.flipper {
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;    
    position: relative; background: transparent;
}

/* hide back of pane during swap */
.front, .back {
    @extend .shadow; @extend .rounded-lg;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;    
    position: absolute;
    top: 0;
    left: 0;
}

/*  UPDATED! front pane, placed above back */
.front {
    z-index: 2;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
    background: $white;
}

/* back, initially hidden pane */
.back {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    background: theme-color("secondary");
}

// Image Gallery
.trz-gallery{
    &__block{
        @extend .position-relative;
        @extend .overflow-hidden;
    }

    &__overlay{
        @extend .position-absolute;
        @extend .w-100;
        @extend .h-100;
        @include transition(all 0.5s);
        background: rgba($primary, 0.85);
        top:100%;
    }
}

.js-item-caption{
    @include transition(all 0.5s);
    opacity: 0;
}
.js-item-caption.fade.in{opacity: 1;}
.trz-gallery__block:hover .trz-gallery__overlay{top:0;}

#horizontal { 
    @extend .w-100;
    @extend .position-relative;
    @extend .ml-0;
    @include transition(right 0.5s);
    right: 0;
}

// Trazi Benefits
.trz-benefits{
    @include media-breakpoint-down(md) {
        padding-top:55px; 
    }

    &__block{
        @include media-breakpoint-up(md) {
          min-height: 165px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 140px;
        }

        @include media-breakpoint-up(xl) {
            min-height: auto;
        }
    }

    &__bg-text{
        @include fontSize(100);
        top:-15px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#efefef+0,f9f8f8+48,efefef+100&1+0,0.85+100 */
        background: -moz-linear-gradient(left, rgba(239,239,239,1) 0%, rgba(249,248,248,0.93) 48%, rgba(239,239,239,0.85) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(239,239,239,1) 0%,rgba(249,248,248,0.93) 48%,rgba(239,239,239,0.85) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(239,239,239,1) 0%,rgba(249,248,248,0.93) 48%,rgba(239,239,239,0.85) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefef', endColorstr='#d9efefef',GradientType=1 ); /* IE6-9 */    
   
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -ms-background-clip: text;
        -o-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        -ms-text-fill-color: transparent;
        -o-text-fill-color: transparent;
        opacity: 0.85;

        // Permalink - use to edit and share this gradient:
        // https://colorzilla.com/gradient-editor/#efefef+0,f9f8f8+48,efefef+100&1+0,0.85+100
        // @include filter-gradient(#efefef, #d9efefef, horizontal); // IE6-9
        // @include background-image(linear-gradient(left, rgba(239,239,239,1) 0%,rgba(249,248,248,0.93) 48%,rgba(239,239,239,0.85) 100%));

        @include media-breakpoint-up(md) {
            @include fontSize(200);
            top: 0;
        }       
    }

    &__icon{
         min-width:calculateRem(55);
    }

    &__info{border-bottom:$border-width * 2 solid $gray-200}
}

 // IE10 and IE11
        // ==================
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .trz-benefits__bg-text{ color: $gray-200 }
}

// Beautifull City
.trz-location{
    overflow: visible !important;
    margin-bottom:50%;

    @include media-breakpoint-up(md) {
        margin-bottom:0;
    }

    &__caption{
        @include media-breakpoint-down(md) {
            @include fontSize(30);
        }
    }

    @include media-breakpoint-between(md, lg) {
        padding:3rem 0;
    }

    &__block{
        @extend .w-100;
        margin-bottom: -50%;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            @include position(absolute, $left:50% , $top:50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }        
    }

    &__media-wrapper{
        margin-top: 2rem ;
        margin-bottom: 2rem;
        width:90% !important;

        @include media-breakpoint-up(md){
            margin:0;
        }
    }

    &__media-img{
        max-height:calculateRem(422);
    }

    &__media-map{
        @include position(absolute, $bottom:-45%, $right:60%);
        width: 90%;
        margin-right:-30%;
    }
}

// Job Openings

.trz-job-openings{

    @include media-breakpoint-up(lg){
        margin-top: calculateRem(100);
    }

    &__block{
        border-bottom:$border-width * 2 solid $gray-200;
    }

    &__btn{
        @include fontSize(20);
    }
}