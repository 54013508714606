// Home page

/*--Expertise--*/
.trz-our-expertise{ 
    @include background-image('../images/mask_bg', 'svg', 100%, auto, center, center, no-repeat);

    &__block{
        @include transition(all 0.5s);
        padding: $spacer $spacer $spacer/2;
        margin:0 0 $spacer;

        @include media-breakpoint-up(md){
            padding: $spacer *1.5 $spacer *1.5 $spacer/2;
            margin:0 0 $spacer *2;
        }

        @include media-breakpoint-up(lg){            
            min-height: calculateRem(330);
        }

        @include media-breakpoint-up(xl){
            padding: $spacer *2.5 $spacer *2.5 $spacer*1.5;
        }
        
        &:hover{ margin-top:-5px;}   
    }
}

/*--Line seprator Globally Used in pages--*/
.line-seprator{
    @extend .d-block;
    @include size(calculateRem(70), calculateRem(3));
    margin:0.75rem auto 1.25rem;
}

/*--Products--*/
.trz-products{
    @include background-image('../images/primary_bg', 'svg', 100%, auto, center, bottom, repeat-x);

    &__block{
        @include transition(all 0.5s);
        padding: $spacer *1.25 $spacer;
        background-color: rgba($black, 0.08);
    }

    &__count{
        @include fontSize(30);
        @extend .text-white;
        @extend .line-height-normal;
        font-family:$futura-bold;
        
        @include media-breakpoint-up(md) {
            @include fontSize(32);            
        }

        @include media-breakpoint-up(lg) {
            @include fontSize(48);
            line-height:calculateRem(60);
        }

        @include media-breakpoint-up(xl) {
            @include fontSize(60);
        }       
    }
    &__plus-sign{
        font-size:60%;
        vertical-align: top;
        line-height:2.125rem; 
        
        @include media-breakpoint-up(md) {
            line-height:2.5rem;             
        }

        @include media-breakpoint-up(lg) {
            line-height:3.5rem;
        }

        @include media-breakpoint-up(xl) {
            line-height:3.6rem;
        }    
    }

    &__title{
        @include media-breakpoint-down(sm) {
            max-width:calculateRem(105);
            margin: 0 auto;          
        }
    }    
}

/*--Page quic Navigation--*/
.trz-navigate{   

    &__block{ 
        @include transition(all 0.5s);
        background-color: rgba($black, 0.15);
        
        a{
            @extend .rounded-lg;
            @include transition(none);/*to fixed ie edge link focus issue*/
            padding: $spacer*1.875 $spacer;           
       
            @include media-breakpoint-up(lg) {padding: $spacer*2.5 $spacer;}       

            .arrow{
                @extend .position-relative;
                @include transition(all 0.5s);
                left:0px;                  
            }

            &:hover, &:focus{
                color: $white !important;
                .arrow{left:5px;}        
            }        
        }

        &:hover{
            background-color: rgba($black, 0.35);
        }
    }   
}

/*--Bg Secondary Opac. It can be use globally--*/
.bg-secondary--opac{
	@include transition(all 0.5s);
	background-color: rgba($black, 0.15);
  
	&:hover{
		background-color: rgba($black, 0.35);
	}       
}

/*--Join Us--*/
.trz-join-us{
  	background-image: linear-gradient(257deg, rgba(72, 102, 242, 0.08) , rgba(72, 102, 242, 0) 52%,  rgba(72, 102, 242, 0.08));
}
