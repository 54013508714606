// button Customisation
.btn {
	@extend strong;
	text-transform: uppercase;
	letter-spacing:0.73px;
	box-shadow:$btn-hover-shadow;

	@include hover {
		box-shadow:$btn-hover-shadow;
		-webkit-ransform: translate(0, -1px);
		-moz-transform: translate(0, -1px);
		-ms-transform: translate(0, -1px);
		-o-transform: translate(0, -1px);
		transform: translate(0, -1px);
	}

	&:focus,
	&.focus {
		@extend .shadow-none; 
		color: $white;
		-webkit-ransform: translate(0, 0);
		-moz-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {   
		@extend .shadow-none; 
		cursor: default;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		@extend .shadow-none; 
	}
}

// button generation and customisation through loop

@each $color, $value in $theme-colors {

  	.btn-#{$color} {
		@extend .border-0;

		&:not(:disabled):not(.disabled):hover{
			
			@include hover {
				background:lighten($value, 4.5%);
				&.btn-primary{ background-color:$primary-hover;}
				&.btn-success{ background-color:$success-hover;}
			}
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background-color:$value;
		}    
  	}
}

// outline button generation and customisation through loop

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		@extend .shadow-none; 

		&:not(:disabled):not(.disabled):hover{
			
			@include hover {
				background:$value;
				color: $white;       
			}			
		}

		&:focus,
		&.focus {
			@extend .shadow-none; 
			color: $value;
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background-color:$value;
		}
	}
}


// Button Size

.btn-lg{  min-width: 258px; 
 
  letter-spacing:0.75px;
  height: 60px;
  @include border-radius(30px);

      @include media-breakpoint-down(sm) {
        letter-spacing : 0.67px;
        padding-left:($spacer - 0.25);
        padding-right:($spacer - 0.25);
        font-size: $font-size-base;
        min-width: 230px;
        line-height:$input-btn-line-height *1.25;   
    }
}

@include media-breakpoint-down(sm) {
	.btn-sm-block{ width: 100%;}
}
 
.btn-link {
	@extend .shadow-none;
	text-transform:none;
	&:focus{
		color: $link-color;
	}
}