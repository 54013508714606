
/*
    ==========================
    Local Fonts Face Mixin 
    ==========================
*/

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?#iefix",
		svg: "#" + str-replace($name, " ", "_"),
	);

	$formats: (
		eot: "embedded-opentype",
		otf: "opentype",
		ttf: "truetype"    
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    	$src: append($src, url(quote("../"+$path + "." + $extmod)) format(quote($format)), comma);   
	}

	@font-face {
		font-family: quote($name);
		font-display: auto;
		font-style: $style;
		font-weight: $weight;
		$src: append($src, url(quote("../"+$path + "." + eot)) format(quote(eot)), comma);
			src: $src;
		}
}

@mixin fontSize($size) {
	font-size: $size+px; //Fallback in px
	font-size: calculateRem($size);
}

/*
    ==========================
    Width & Height Mixin
    ==========================
*/

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

/*
    ==========================
    Position Mixin
    ==========================
*/

@mixin position($position:null, $top:null, $right:null, $bottom:null, $left:null){
	position:$position;
	top:$top;
	right:$right;
	bottom:$bottom;
	left:$left;
}

/*
    ==========================
    Box Shadow Mixin
    ==========================
*/

  @mixin box-shadow($top, $left, $blur, $color, $inset: false) {
		@if $inset {
			//   -webkit-box-shadow:inset $top $left $blur $color;
			//   -moz-box-shadow:inset $top $left $blur $color;
			box-shadow:inset $top $left $blur $color;
		} @else {
			//   -webkit-box-shadow: $top $left $blur $color;
			//   -moz-box-shadow: $top $left $blur $color;
			box-shadow: $top $left $blur $color;
		}
  }

// stylelint-disable property-blacklist
// Bootstrap transition mixin for crossbrowser support
@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      -moz-transition: $transition-base;
      -webkit-transition: $transition-base;
      -ms-transition: $transition-base;
      -o-transition: $transition-base;
      transition: $transition-base;
    } @else {
      -moz-transition: $transition;
      -webkit-transition: $transition;
      -ms-transition: $transition;
      -o-transition: $transition;
      transition: $transition;
    }
  }

  @if $enable-prefers-reduced-motion-media-query {
    @media (prefers-reduced-motion: reduce) {
      -moz-transition: none;
      -webkit-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
}


/*
    ==========================
    Background Mixin
    ==========================
*/

@mixin background-image($file, $type, $width:null, $height:null, $positionX: left, $positionY: top, $background-repeat: no-repeat) {
	background-image:url($file + '.' + $type);
	background-size: $width $height;
	//width: $width; height: $height;
	background-position: $positionX $positionY;
	background-repeat: $background-repeat;  
}

// / Mixin printing a linear-gradient
// / as well as a plain color fallback
// / and the `-webkit-` prefixed declaration
// / @access public
// / @param {Keyword | Angle} $direction - Linear gradient direction
// / @param {Arglist} $color-stops - List of color-stops composing the gradient

  @mixin linear-gradient($direction, $color-stops...) {
	// Direction has been omitted and happens to be a color-stop
	@if is-direction($direction) == false {
	  $color-stops: $direction, $color-stops;
	  $direction: 180deg;
	}  
	background: nth(nth($color-stops, 2), 1) !important;
	background: -webkit-linear-gradient(legacy-direction($direction), $color-stops) !important;
	background: -moz-linear-gradient(legacy-direction($direction), $color-stops) !important;
	background: linear-gradient($direction, $color-stops) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=nth(nth($color-stops, 1), 1), endColorstr=nth(nth($color-stops, 2), 1),GradientType=1 )!important; /* IE6-9 */
  }

// / Test if `$value` is a valid direction
// / @param {*} $value - Value to test
// / @return {Bool}
@function is-direction($value) {
	$is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
	$is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  
	@return $is-keyword or $is-angle;
  }

// / Convert a direction to legacy syntax
// / @param {Keyword | Angle} $value - Value to convert
// / @require {function} is-direction
// / @require {function} convert-angle
// / @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
	@if is-direction($value) == false {
	  @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
	}
  
	$conversion-map: (
	  to top          : bottom,
	  to top right    : bottom left,
	  to right top    : left bottom,
	  to right        : left,
	  to bottom right : top left,
	  to right bottom : left top,
	  to bottom       : top,
	  to bottom left  : top right,
	  to left bottom  : right top,
	  to left         : right,
	  to left top     : right bottom,
	  to top left     : bottom right
	);
  
	@if map-has-key($conversion-map, $value) {
	  @return map-get($conversion-map, $value);
	}
  
	@return 90deg - $value;
  }