//Perspective image hover effect used in about us page and career page
.perspectiveHoverEffect {
	@extend .w-100;
	@extend .position-relative;
	cursor: pointer;    
}

.l-20{left:20%;}
.h-80{height:80%;}
.r--10{right:-10%}
.mt-md--3{
	@include media-breakpoint-up(md) {            
			margin-top: -3rem
	}
}