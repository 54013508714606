// Page Wrapper

/*--Section--*/
.section{
    @extend .position-relative;
    @extend .overflow-hidden;
    padding:$spacer*2 0;    

    @include media-breakpoint-up(md) {
        padding:70px 0;
    }
}
