//
// Forms
//

label {
  // Allow labels to use `margin` for spacing.
    @extend .font-size-lg;
}
// Customization form control
.form-control {

// Customize the `:focus` state to imitate native WebKit styles.
@include form-control-focus();
    &:focus{
        @extend .shadow-none; 
}
  
// Disabled and read-only inputs
//
// HTML5 says that controls under a fieldset > legend:first-child won't be
// disabled if the fieldset is disabled. Due to implementation difficulty, we
// don't honor that edge case; we style them as disabled anyway.
&:disabled,
&[readonly] {
    border-color: $input-disabled-border;
    color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.   
    @extend .shadow-none; 
  }
}


.custom-select{-webkit-appearance: none; -moz-appearance: none; appearance: none}
// Customization control input

.custom-control-input {
    &:focus ~ .custom-control-label::before {
    // the mixin is not used here to make sure there is feedback   
        @extend .shadow-none;  
    }
}

// // Customization checkbox



// // Customization Radio



// Customization File input
.custom-file-input {
  .was-validated &:invalid,
    &.is-invalid, .was-validated &:valid,
    &.is-valid {
        ~ .custom-file-label {
            padding-right: calc(1.5em + .75rem);
            background-repeat: no-repeat;
            background-position: center right calc(.375em + .1875rem);
            background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        } 
    }

    .was-validated &:invalid,
    &.is-invalid {
      ~ .custom-file-label {
            background-image:url('../images/cross.svg');
            border-color:$form-feedback-invalid-color;
        } 
    }

    .was-validated &:valid,
    &.is-valid {
      ~ .custom-file-label {
            background-image:url('../images/tick.svg');
            border-color:$form-feedback-valid-color;
        } 
    }

    &:focus ~ .custom-file-label {
        //border-color: $custom-file-focus-border-color;
        @extend .shadow-none; 
    }
}

.custom-file-lg{
    height: calc(1.5em + 1.5rem + 2px);

    .custom-file-input{
        height: calc(1.5em + 1.5rem + 2px);
    }

  label{
        height: calc(1.5em + 1rem + 2px);
        padding: .5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: .3rem;

      &:after{
            height: calc(1.5em + 1rem + 2px);
            padding: .5rem 1rem;
            font-size: 1.25rem;
            line-height: 1.5;
            border-radius: 0 .3rem .3rem 0;
            top:-1px; left:-1px; bottom:-1px;
        }
    }
}



// Customization Validation

.was-validated .form-control:invalid,
.form-control.is-invalid{ 
    background-image:url('../images/cross.svg');
}

.was-validated .form-control:valid,
.form-control.is-valid{
    background-image:url('../images/tick.svg');
}