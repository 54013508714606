//About

/*--Define Trazi--*/

.trz-define-trazi {
    @include background-image('../images/light_dots_bg', 'svg', 100%, auto, center, bottom, repeat-x);
}


/*--Hiring Process--*/

.trz-hiring-process {
    //&__block {}
    .card-deck {
        @include media-breakpoint-down(sm) {
            flex-flow: column;
            .card {
                margin-bottom: calculateRem(70);
            }
        }
    }
    .add-seprator {
        @include size(calculateRem(38));
        @include position($left: 0px, $right: 0, $bottom: -54px);
        @extend .mx-auto;
        z-index: 1;
        @include media-breakpoint-up(md) {
            @include position($left: auto, $right: -35px, $top: 2rem, $bottom: auto);
        }
        @include media-breakpoint-only(md) {
            @include size(calculateRem(31));
            right: -31px;
        }
    }
}


/*--Tribe--*/

.trz-tribe-sec {
    @include background-image('../images/flag_bg', 'svg', auto, auto, right, bottom, no-repeat);
    @include media-breakpoint-up(lg) {
        padding-top: calculateRem(130);
    }
    &__media-wrapper {
        width: 83.5%;
        @include media-breakpoint-up(lg) {
            margin-top: -12.5%;
        }
    }
    &__media-img {
        max-height: 601px;
    }
}


/*--Testimonial--*/

.trz-testimonial {
    background: rgba($white, 0.5);
    .blockquote-footer {
        &:before {
            @extend .d-none;
        }
    }
}


/*--Intro with parallax effect--*/

.trz-intro-prallax {
    @include background-image('../images/dawid_zawila_unsplash', 'jpg', auto, auto, 0, 0, no-repeat);
    &__overlay {
        @include position($left: 0, $right: 0, $top: 0, $bottom: 0);
        background-color: rgba(44, 46, 78, 0.25);
    }
    &__row {
        height: calculateRem(300);
    }
    &__caption {
        @include fontSize(30);
        text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        @include media-breakpoint-only(md) {
            white-space: nowrap;
        }
    }
    &__display-text {
        @include fontSize(40);
        bottom: -15%;
        @include media-breakpoint-up(md) {
            @include fontSize(110);
            bottom: -25%;
        }
        @include media-breakpoint-up(lg) {
            @include fontSize(130);
            bottom: -25%;
        }
    }
}


/*--How it started--*/

.trz-how-it-started {
    @include background-image('../images/parasuit_bg', 'svg', 200px, 291px, right, 80px, no-repeat);
    @include media-breakpoint-up(md) {
        padding-top: calculateRem(80);
        padding-bottom: calculateRem(130);
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: calculateRem(80);
    }
    &__media-wrapper {
        height: calculateRem(245);
        margin-right: 1.25rem;
        @include media-breakpoint-up(md) {
            margin-right: 3rem;
        }
    }
    &__media-img {
        -webkit-transform: translate(0, -30%);
        -moz-transform: translate(0, -30%);
        -ms-transform: translate(0, -30%);
        -o-transform: translate(0, -30%);
        transform: translate(0, -30%);
    }
    &__testimonial {
        margin: calculateRem(-170) 0 2rem 1.25rem;
        @include media-breakpoint-up(md) {
            margin: calculateRem(-140) 0 0px calculateRem(40);
        }
    }
    &__blockquote {
        border-left: $border-width *4 solid $primary;
    }
    &__btn-career {
        top: 50px;
        position: relative;
    }
}
.trz-instagram {
    text-align: center;
    background-image: linear-gradient(257deg, rgba(72, 102, 242, 0.08) , rgba(72, 102, 242, 0) 52%,  rgba(72, 102, 242, 0.08));
    padding: {
        top: 32px;
        bottom: 32px;
    }
    @include media-breakpoint-up(md) {
        padding-top: calculateRem(70);
        padding-bottom: calculateRem(80);
    }
    & a {
        &:not(.ig-card) {
            display: inline-block;
        @include transition(all 0.5s);
        &:hover{ opacity: 75%; }
        }
    }
    &__posts {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
.ig-card {
    width: 300px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 20px;
    position: relative;
    top: 0;
    text-align: left;
    @include transition(all 0.5s);
    &:hover{ top: -5px; }
    &__media-type-icon {
        position: absolute;
        top: 13px;
        right: 19px;
        @include transition(all 0.5s);
        &:hover{ filter: opacity(0.8) }
    }
    &__url {
        height: 270px;
        width: 283px;
        overflow: hidden;
        margin: 8px;
        object-fit: cover;
    }
    &__footer {
        height: 182px;
        padding: 14px 20px;
        color: #666;
    }
    &__logo {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        margin-right: 8px;
    }
    & p {
        margin-top: 5px;
        height: 100px;
        line-height: 1.5;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden !important;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
    }
    &__icons {
        display: flex;
        justify-content: flex-end;
        margin-top: -10px;
        & img {
            margin-left: 9px;
        }
    }
}
