.trz-header{  
    @extend .position-fixed;
    @extend .w-100;    
    z-index: 99;
    padding: $spacer 0;
    @include media-breakpoint-up(md) {
        padding: $spacer * 1.25 0;
        @include transition(all, 0.4s);
    }   

    // Logo on transparent background

    &__logo{
        @include media-breakpoint-down(sm) {
            @include size(80px, auto);
            position: relative;
            z-index: 99;
        }
    }    

    .logo-white{ display: inline-block;}
    .logo-colored{display: none}

    // Menu Toggle for mobile

    .toggle-menu{
        @extend .position-absolute;
        @include size(40px, 40px);
        @include transition(all, 0s);
        top: -5px;
        right: .75rem;
        cursor: pointer;
        z-index: 99;

        .icon-bars{
            @extend .d-block; 
            @extend .position-relative;
            @include size(28px, 2px);
            background:$white;
            top: 20px;
            left: 6px;

            &:after, &:before{
                content:'';
                @extend .position-absolute;
                @extend .d-block;
                @include size(28px, 2px);
                @include transition(all, 0.4s);
                background:$white;               
            }

            &:before{top:-8px;}
            &:after{top:8px;}          
        }

        &.open{
            .icon-bars{
                height:0px;
                &:before{transform: rotate(45deg); top:0;}
                &:after{transform: rotate(-45deg) ; top:0;}  
            }
        }
    }

    // Nav and desktop menu

    nav{
        position: static;
        @include media-breakpoint-up(md) {
            position: relative;
        }

        ul{ @extend .mb-0;          

            @include media-breakpoint-up(md) {
                margin-top: $spacer/4;
            }

            li{ @extend .d-md-inline-block;
                margin:0 0 0 $spacer*1.25; 

                    @include media-breakpoint-up(lg) {
                        margin:0 0 0 $spacer*2; 
                    }

                a{  @extend .position-relative; 
                    @extend .text-uppercase; 
                    @extend .py-1;
                    @extend strong;
                    @include transition(all 0.4s ease-in-out);
                    color:$white;
                    font-size: $font-size-sm;                                       
                    letter-spacing: 0.75px;
                    

                    &:after{
                        content:'';
                        @extend .position-absolute;
                        @include size(0%, 2px);
                        @include transition(all 0.5s);
                        background:$blue;
                        transform: translate(-50%, 0);
                        bottom:-5px;
                        left:50%;

                        @include media-breakpoint-up(md) {
                            background: $white;
                        }
                    }
                    &:hover{
                        &:after{
                            @include size(100%, 2px);
                        }
                    }

                    &.active{
                        &:after{
                            @include size(100%, 2px);
                            transform: none;
                            transition: none;
                            left: 0;                           
                        }
                    }
                }
            }
        }

        // Mobile menu  

       .mobile-menu{

            @include media-breakpoint-down(sm) {
                @include position(absolute, $left:0, $right:0, $top:-1rem);
                @include transition(height 0.6s  ease-in-out);
                background: rgba($dark-blue, 0.96);
                overflow: hidden;
                text-align: center;
                height:0;                
                
                &.show{
                    height:378px;
                   
                }

                ul{ margin-top:84px;

                    li{
                         margin: $spacer *1.5 0;
                    }                        
                }
            }               
        }
    }

    // Light header theme on scroll

    &--theme-light{
        @extend .bg-white;
        @include box-shadow(0, 1px, 4px, rgba(76, 105, 254, 0.2));

        // Logo swap on scroll

        .logo-white{ display: none}
        .logo-colored{ display: inline-block;}

        // Toggle Menu swap on scroll 

        .toggle-menu{

            .icon-bars{
                background:$dark-blue;

                &:after, &:before{
                    background:$dark-blue;
                }         
            }
        }

        // Nav menu swap on scroll 
        
        nav{
            ul { 
                @include media-breakpoint-down(sm) {
                    margin-top:0 !important ;
                }

                li{  
                    a{
                        @include media-breakpoint-up(md) {
                            color:$dark-blue;
                        }

                        &:after{
                            background: $blue;
                        }        
                    }
                }     
            }
            
            // Mobile menu when light header theme on scroll 

            .mobile-menu{
                @include media-breakpoint-down(sm) {
                    top:44px !important;

                    &.show{
                        height:318px;
                    
                    }
                }               
            }
        } 
    }
}  
