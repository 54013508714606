//footer
.trz-footer {
  background: $footer-bg;
  @extend .py-3;
  @extend .py-md-4;
  @extend .text-white;

  &__copyright {
    color: #fff;
    font-family: "futurabook";
    @include media-breakpoint-down(sm) {
      font-size: $font-size-xs;
    }
  }
  &__medical-disclosure {
    @extend .text-white;
    font-family: "futurabook";
    border-left: 1px solid #fff;
    padding-left: 15px;
    margin-left: 15px;
    &:hover {
      opacity: 0.8;
    }
  }

  &__footer-nav {
    @extend .mb-0;
  }

  &__nav-link {
    @extend .text-white;
    @extend .text-uppercase;
    @extend strong;
    @include transition(all 0.4s);
    font-size: $font-size-sm;

    &:hover {
      opacity: 0.8;
    }
  }
}
