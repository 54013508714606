// Bootstrap


/* 1. import only the necessary Bootstrap files */
@import "../../node_modules/bootstrap/scss/functions"; 
@import "../../node_modules/bootstrap/scss/variables";


// Base
@import 'base/vars';
@import 'base/function';

/*--Bootstrap--*/
@import "bootstrap";

/*--Mixin--*/
@import 'base/mixins';

/*--reboot and typography--*/
@import 'base/normalize'; 
@import 'base/typography'; 


// Elements
@import 'elements/buttons'; 
@import 'elements/form'; 


// Layout
@import 'layout/header';
@import 'layout/wrapper';
@import 'layout/footer'; 

// Module 
@import 'module/background';
@import 'module/banner-section';
@import 'module/perspective_media';


// Components
/*--Home--*/
@import 'components/home';

/*--About Us--*/
@import 'components/about'; 

/*--Our Team--*/
@import 'components/team';

/*--Brands --*/
@import 'components/brands'; 

/*--Careers--*/
@import 'components/careers'; 

/*--Contact--*/
@import 'components/contact';