// typography partial

*:focus{outline:0 none;}

//Custom Fonts 
@include font-face("futuraheavy_italic", fonts/futura_heavy_italic, normal, normal);
@include font-face("futuraheavy", fonts/futura_heavy, normal, normal);
@include font-face("futurabook_italic", fonts/futura_book_italic, normal, normal);
@include font-face("futurabook", fonts/futura_book, normal, normal);

// heading Style and sizes
h1, h2, h3, h4, h5, h6{
    font-weight:$font-weight-normal;
    // line-height: $line-height-base;    
}
h1, h2, h3, h4{ 
    font-family:$futura-bold;  
    color: $dark-blue; 
    @extend .line-height-normal;
}

h1{
    @include media-breakpoint-up(md) {
        @include fontSize(60);
    } 
}

h2{
    @include media-breakpoint-up(md) {
        @include fontSize(44);
    } 
 }

h3{
    @include media-breakpoint-up(md) {
        @include fontSize(30);
    }     
}

// links
a{
    
    @include transition(all 0.3s  ease-in);

    &:focus, &:hover{
      color:$link-hover-color ;
    }
}


/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

 b, strong, .font-weight-bold{
    font-family:$futura-bold;
    font-weight:$font-weight-normal;
  }
  
small, .small{font-size: $font-size-xxs;}

// Fonts weight and line height in normal state
.line-height-normal{line-height:normal}
.font-size-lg{font-size:$h5-font-size }
.text-black{color:$black }
