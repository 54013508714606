
//Team

.trz-team{

    &__media-wrapper{
        @extend .overflow-hidden;
        @include box-shadow(0, 10px, 40px, rgba(72, 102, 242, 0.15));
        @include size(calculateRem(130));
    }

    .no-touch &{
        &__block{
            img{
                @include transition(all 0.5s);
                -moz-transform:scale(1);
                -webkit-transform:scale(1);
                -ms-transform:scale(1);
                -o-transform:scale(1);
                transform:scale(1);
            }

            &:hover{                
                img{
                    -moz-transform:scale(1.1);
                    -webkit-transform:scale(1.1);
                    -ms-transform:scale(1.1);
                    -o-transform:scale(1.1);
                    transform:scale(1.1);
                }
            }          
        }
    }
}