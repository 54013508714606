// Contact

.trz-contact-us{
    z-index: 10; 
    margin-top:calculateRem(-170); 

    @include media-breakpoint-up(sm){
        margin-top:calculateRem(-210);
    }

    @include media-breakpoint-up(md){
        margin-top:calculateRem(-180);
    }

    @include media-breakpoint-up(lg){
        margin-top:calculateRem(-160);
    }
}

/*--Custom File--*/

.trz-custom-file{
    label{      
        &:after{
            @include border-radius(.3rem 0 0 .3rem);
            @include position($left: -1px, $right:auto);
            @extend .bg-primary;
            @extend .text-white;    
            border-left:0;
        }
    }
}
