//Banner
.trz-banner-section{
	@extend .overflow-hidden;
	background: $black;
	padding:$spacer *6 0 $spacer * 6;

        @include media-breakpoint-up(md) {
            padding:$spacer * 12 0 $spacer * 12;
        }

        &--inner-banner{
            min-height: 400px;
            padding:$spacer *6 0 $spacer * 6;
            
            @include media-breakpoint-up(md) {
                padding:$spacer *7 0 $spacer *7;				
            }
        }

	&__hero-img{
        @extend .position-absolute;
        @include position($top: 0, $right: 0, $left:50%);
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
		height:calculateRem(600);
		max-width: none;

		@include media-breakpoint-up(sm) {
			max-width: 100%;
			height:auto;
		}

		&--about-hero-img{

			@include media-breakpoint-up(md) {
                -webkit-transform: translate(-50%, -30%);
                -moz-transform: translate(-50%, -30%);
                -ms-transform: translate(-50%, -30%);
                -o-transform: translate(-50%, -30%);
                transform: translate(-50%, -30%);
			}

			@include media-breakpoint-up(lg) {
                -webkit-transform: translate(-50%, -35%);
                -moz-transform: translate(-50%, -35%);
                -ms-transform: translate(-50%, -35%);
                -o-transform: translate(-50%, -35%);
                transform: translate(-50%, -35%);
			}
		}
	}

	&__info-block{
		@extend .position-relative;
		z-index:1;	
    }
    
	&__caption{
		@extend .line-height-normal;
	}

	&__sub-desc{line-height: 1.5rem;}
}

.overlay-img{opacity:0.12;}

canvas {
	display: block;
	vertical-align: bottom;
}
  
/* ---- particles.js container ---- */

#particles-js {
    @include position(absolute, $top:0);
    @include size(100%);
}