
// brands

.trz-brands{
    &__block{
        @include transition(all 0.5s);
        &:hover{ margin-top:-5px;}  
    }

    &__media-wrapper{
        width:calculateRem(90); 
        
        @include media-breakpoint-up(lg) {
            width:calculateRem(100);
        }
    }

    &__icon-lock{
        @include media-breakpoint-down(lg) {
            @include size(calculateRem(90), auto);
        }
    }

    &__counts{
        @include fontSize(44);
        @include position($top:0, $right:0, $bottom:0, $left:0);
        -webkit-transform: translate(0%, 50%);
        -moz-transform: translate(0%, 50%);
        -ms-transform: translate(0%, 50%);
        -o-transform: translate(0%, 50%);
        transform: translate(0%, 50%);
    }

    &__caption{
        @include media-breakpoint-down(lg) {
            @include fontSize(24);
        }
    }
}